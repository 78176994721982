import {useOutlet} from 'reconnect.js';
import {useCallback, useEffect, useState} from 'react';
import * as L from '../Utils/Lang';

export const useMealComboList = ({cinemaId}) => {
  const [actions] = useOutlet('actions');
  const [mealComboList, setMealComboList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const fetchMealComboList = useCallback(async () => {
    try {
      setIsLoading(true);
      const resp = await actions.fetchMealComboList({cinemaId: cinemaId});
      setMealComboList(
        resp.map((c) => ({
          id: c.Id,
          name: c.Name,
          concessionItems: c.ConcessionItems.map((m) => ({
            ...m,
            id: m.Id,
            title: L.d({zh: m.DescriptionAlt, en: m.Description}),
            subtitle: L.d({
              zh: m.ExtendedDescriptionAlt,
              en: m.ExtendedDescription,
            }),
            priceInCents: m.PriceInCents,
            restrictToLoyalty: m.RestrictToLoyalty,
            packageChildItems: m.PackageChildItems,
            isPackage: m.PackageChildItems.length > 0,
          })),
        })),
      );
    } catch (e) {
      console.log('debug', e);
    } finally {
      setIsLoading(false);
    }
  }, [actions, cinemaId]);

  useEffect(() => {
    fetchMealComboList().then(() => 0);
  }, [cinemaId, fetchMealComboList]);

  return {
    mealComboList,
    isLoading,
  };
};
