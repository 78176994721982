import React from 'react';
import styled from 'styled-components';
import {Color} from '../constants';
import {FlexColumnCenterStart, FlexRowCenterCenter} from '../widget';

export const BlockSelector = (props) => {
  const {active, setActive, options, style = {}} = props;
  return (
    <StyledBlockSelector style={style}>
      {options.map((opt, i) => {
        const act = active === opt.value;
        return (
          <div
            key={i}
            className={`film-status-option ${act ? 'active' : ''}`}
            onClick={() => {
              setActive(opt.value);
            }}>
            {opt.name}
          </div>
        );
      })}
    </StyledBlockSelector>
  );
};

const StyledBlockSelector = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: ${Color.black_10};
  padding: 3px;
  margin-bottom: 18px;
  border-radius: 10px;
  & > .film-status-option {
    flex: 1;
    ${FlexRowCenterCenter};
    text-align: center;
    border-radius: 10px;
    background-color: transparent;
    color: ${Color.black};
    font-size: 12px;
    cursor: pointer;
    &:active {
      opacity: 0.3;
    }
  }
  & > .film-status-option.active {
    flex: 1;
    text-align: center;
    border-radius: 10px;
    background-color: ${Color.white};
    color: ${Color.orange};
  }
`;
