import styled from 'styled-components';
import {Color} from '../constants';

export const Badge = styled.div`
  height: 20px;
  padding: 0 5px;
  background-color: ${Color.orange};
  color: ${Color.white};
  border-radius: 5px;
  font-size: 10px;
  line-height: 20px;
`;
