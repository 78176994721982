export function getAggregateVistaConcessions(concessions) {
  return concessions.reduce((acc, cur) => {
    const targetIndex = acc.findIndex((c) => c.ItemId === cur.ItemId);

    if (targetIndex > -1) {
      acc[targetIndex].QuantityBooked += cur.QuantityBooked;
    } else {
      acc = [...acc, {...cur}];
    }
    return acc;
  }, []);
}
