import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import Spinner from '../../Components/Spinner';
import {useMealComboList} from '../../Hooks/useMealComboList';
import {navigate} from 'gatsby-link';
import {BlockSelector} from '../../Components/BlockSelector';
import {SectionHeader} from '../Landing/SectionHeader';
import {MealItem} from '../../Components/MealItem';

const MealList = (props) => {
  const {mealComboList, isLoading: isMealLoading} = useMealComboList({
    cinemaId: 1001,
  });
  const [activeCombo, setActiveCombo] = useState('');

  useEffect(() => {
    if (isMealLoading) {
      return;
    }
    setActiveCombo(mealComboList[0].name);
  }, [isMealLoading, mealComboList]);

  if (isMealLoading) {
    return <Spinner />;
  }

  return (
    <Wrapper>
      <BlockSelector
        active={activeCombo}
        setActive={setActiveCombo}
        options={mealComboList.map((c) => ({
          name: c.name,
          value: c.name,
        }))}
      />
      {mealComboList
        .filter((c) => c.name === activeCombo)
        .map((c, index) => (
          <div key={index}>
            <SectionHeader title={c.name} caption="Foods" />
            {c.concessionItems.map((m, i) => {
              return (
                <MealItem
                  key={i}
                  onClick={async () => {
                    await navigate(`/meal-detail?id=${m.id}`);
                  }}
                  meal={m}
                />
              );
            })}
          </div>
        ))}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin: var(--topNavBarHeight) auto 0 auto;
  max-width: var(--contentMaxWith);
  padding: var(--basePadding);
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - var(--topNavBarHeight));
`;

export default MealList;
