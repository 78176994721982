import {ChevronRight} from '@styled-icons/material';
import React from 'react';
import styled from 'styled-components';
import {Breakpoints, Color} from '../../constants';

export const SectionHeader = ({
  caption,
  title,
  button,
  style = {},
  colors = {title: Color.black_50, caption: Color.orange},
  align = 'left',
}) => {
  return (
    <StyledRowSectionHeader hasButton={!!button} style={style}>
      <div className="header">
        <div
          className="header-caption"
          style={{color: colors.caption, textAlign: align}}>
          {caption.toLocaleUpperCase()}
        </div>
        <div
          className="header-title"
          style={{color: colors.title, textAlign: align}}>
          {title}
        </div>
      </div>
      {button && (
        <button onClick={button.onClick}>
          {button.title}
          <ChevronRight />
        </button>
      )}
    </StyledRowSectionHeader>
  );
};

const StyledRowSectionHeader = styled.div`
  min-width: 211px;
  & > .header {
    & > .header-caption {
      color: ${Color.orange};
      font-size: 12px;
      line-height: 1.57;
      text-align: left;
    }
    & > .header-title {
      color: ${Color.black_50};
      font-size: 36px;
      line-height: 1.57;
      font-weight: bold;
      margin-bottom: ${({hasButton}) => (hasButton ? '30px' : '0')};
      text-align: left;
    }
  }
  & > button {
    position: relative;
    padding: 8px 28px 8px 15px;
    font-size: 12px;
    line-height: 1.57;
    border: none;
    background-color: ${Color.black_10};
    border-radius: 10px;
    cursor: pointer;
    & > svg {
      position: absolute;
      width: 24px;
      height: 24px;
      fill: ${Color.orange};
      font-weight: bold;
      top: 50%;
      transform: translateY(-50%);
    }
    &:hover {
      background-color: ${Color.black_20};
    }
  }
  @media screen and (max-width: ${Breakpoints.md}px) {
    min-width: unset;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    & > .header {
      & > .header-caption {
        font-size: 12px;
        line-height: 1.57;
      }
      & > .header-title {
        font-size: 22px;
        line-height: 1.57;
        margin-bottom: 0;
      }
    }
    & > button {
      background: transparent;
      padding: 8px 0;
      margin-right: 24px;
    }
  }
`;
