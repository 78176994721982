import styled from 'styled-components';
import {
  Card,
  FlexColumnStartStart,
  FlexRowCenterBetween,
  FlexRowStartStart,
} from '../widget';
import {Color, ORDER_STATUS_DISPLAY} from '../constants';
import React, {Fragment} from 'react';
import {Badge} from './Badge';
import moment from 'moment';
import {getFormat} from '../Utils/Price';
import Config from '../../data.json';
import * as L from '../Utils/Lang';
import {getAggregateVistaConcessions} from '../Utils/Concessions';

export const FilmMealItem = (props) => {
  const {onClick, order} = props;

  return (
    <Card clickable onClick={onClick} style={{width: '100%'}}>
      <div style={{margin: '5px 0'}}>
        {moment(order.BookingDate).format('YYYY / MM / DD')}
      </div>
      {getAggregateVistaConcessions(order.Booking.Concessions).map((c, i) => (
        <div
          key={i}
          style={{
            width: '100%',
            margin: '2px 0',
            display: 'flex',
            textAlign: 'left',
          }}>
          <div style={{minWidth: 100}}>
            {L.d({zh: c.DescriptionAlt, en: c.Description})}
          </div>
          <div>x {c.QuantityBooked}</div>
          <div style={{flex: 1}} />
          <div>{getFormat(c.PriceInCents)}</div>
        </div>
      ))}
    </Card>
  );
};

export const MealItem = (props) => {
  const {meal, order, onClick} = props;
  if (order) {
    return (
      <Fragment>
        {order.aggregateConcessions.length > 0 && (
          <StyledMealItem clickable onClick={onClick}>
            <div className="left">
              <div className="context">
                <div style={{marginBottom: 5}}>
                  {moment(order.created).format('YYYY / MM / DD')}
                </div>
                <div className="meals">
                  {order.aggregateConcessions.map((c, i) => {
                    const targetConcession = order.concessions_info.find(
                      (ci) => ci.id === c.id,
                    );
                    const isLast = order.aggregateConcessions.length - 1 === i;
                    return (
                      <span key={i} style={{color: Color.black_70}}>
                        {targetConcession.title} {`${isLast ? '' : ' / '}`}
                      </span>
                    );
                  })}
                </div>
              </div>
            </div>
            <Badge
              style={{
                position: 'absolute',
                top: 10,
                right: 10,
                backgroundColor: ORDER_STATUS_DISPLAY[order.status].color,
              }}>
              {ORDER_STATUS_DISPLAY[order.status].title}
            </Badge>
          </StyledMealItem>
        )}
      </Fragment>
    );
  }
  return (
    <StyledMealItem clickable onClick={onClick}>
      <div className="left">
        <img
          onError={(e) => {
            e.target.src = `https://fakeimg.pl/90/EE6D22/?text= &font=lobster`;
          }}
          src={`${Config.mealImageCdn}${meal.id}`}
          alt="meal"
        />
        <div className="context">
          <div className="title">{meal.title}</div>
          <div className="subtitle">{meal.subtitle}</div>
        </div>
      </div>
      <div className="right">
        <div className="price">{getFormat(meal.priceInCents)}</div>
      </div>
    </StyledMealItem>
  );
};

const StyledMealItem = styled(Card)`
  width: 100%;
  ${FlexRowCenterBetween};
  margin-bottom: 10px;
  padding: 5px 15px;
  max-height: 100px;
  position: relative;
  & > .left {
    ${FlexRowStartStart};
    & > img {
      width: 90px;
      height: 90px;
      object-fit: cover;
      border-radius: 10px;
    }
    & > .context {
      ${FlexColumnStartStart};
      text-align: left;
      padding-top: 10px;
      padding-left: 10px;
      & > .title {
        margin-bottom: 10px;
      }
      & > .subtitle {
        color: ${Color.black_70};
        max-width: 120px;
      }
      & > .meals {
        ${FlexRowStartStart};
      }
    }
  }
  & > .right {
    & > .price {
      color: ${Color.orange};
      font-size: 22px;
      font-weight: bold;
      letter-spacing: 2px;
    }
  }
`;
